import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Popup from "../components/modal&components/Modal";
import { ActionContext, PlayersContext, PopupContext } from "../context.js";
import { playerBg, rightArrow } from "../assets/img";
import { dressingRoom } from "../assets/videos";

const Squad = () => {
  const { hfAction, showHF } = useContext(ActionContext);
  const { players } = useContext(PlayersContext);
  const { popupProps } = useContext(PopupContext);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [isVideoEnded, endVideo] = useState(pathname.split("/").length - 1 > 1);

  const handleNavigation = (page = hfAction.var) => {
    navigate(page);
    hfAction.fn(null);
  };

  const handleClosePopup = () => {
    navigate("/squad");
    hfAction.fn(null);
    popupProps.fn({});
    document.title = "Patna Pirates | Squad";
    showHF("#000");
  };

  const onVideoEnded = () => {
    endVideo(true);
    showHF("#000");
  };

  useEffect(() => {
    if (pathname.split("/").length - 1 === 1) handleClosePopup();
  }, [pathname]); // eslint-disable-line

  useEffect(() => {
    if (hfAction.var) handleNavigation();
  }, [hfAction.var]); // eslint-disable-line

  useEffect(() => {
    showHF(false);

    return () => showHF(true);
  }, []); // eslint-disable-line

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <img src={rightArrow} alt="next" />
      </button>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <img src={rightArrow} alt="next" />
      </button>
    );
  }

  var settings = {
    arrows: true,
    autoplay: false,
    dots: false,
    draggable: false,
    infinite: true,
    initialSlide: 0,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1624,
        settings: {
          slidesToShow: 6,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="wrapper">
      {isVideoEnded ? (
        <>
          {pathname.split("/").length - 1 > 1 ? (
            <Popup onClose={handleClosePopup} {...popupProps.var}>
              <Outlet
                context={{
                  close: handleClosePopup,
                  loading: false,
                }}
              />
            </Popup>
          ) : (
            <div className=" squadPage">
              <Slider {...settings}>
                {players?.map((player, key) => (
                  <div className="playerCol" key={key}>
                    <div className="playerDescp">
                      <div className="playerName">
                        {" "}
                        <h3>{player.player_name}</h3>{" "}
                      </div>
                      <div className="playerPostion"> {player.section} </div>
                      <Link
                        className="playerPicture"
                        style={
                          player.section.includes("Coach")
                            ? { cursor: "not-allowed" }
                            : {}
                        }
                        to={
                          !player.section.includes("Coach")
                            ? `player_detail/${player.id}`
                            : ""
                        }
                      >
                        {" "}
                        <img src={player.player_image} alt="" />{" "}
                      </Link>
                    </div>
                    <img src={playerBg} className="playerImg" alt="" />
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </>
      ) : (
        <div className="videoOuter">
          <video controls={false} autoPlay={true} onEnded={onVideoEnded} muted>
            <source src={dressingRoom} type="video/mp4" />
          </video>
          <button type="button" className="btn skip" onClick={onVideoEnded}>
            SKIP
          </button>
        </div>
      )}
    </div>
  );
};

export default Squad;
