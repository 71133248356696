import React from "react";
import "../assets/css/pagination.css";
import ReactPaginate from "react-paginate";
import { itemsPerPage } from "../utils/fixer";

const Pagination = ({
  currPage = 0,
  items = [],
  handlePageSelect = () => {},
  perPage = itemsPerPage,
}) => {
  // useEffect(() => {
  //   const [elem] = document.getElementsByClassName("modal-content");
  //   if (elem) elem.style.height = "97%";

  //   return () => (elem.style.height = "100%");
  // }, []);

  const pageCount = Math.ceil(items.length / perPage);

  return (
    <ReactPaginate
      containerClassName={"pagination"}
      activeClassName={"paginationActive"}
      disabledClassName={"paginationDisabled"}
      forcePage={currPage}
      marginPagesDisplayed={1}
      nextLabel={""}
      nextLinkClassName={"paginationLink"}
      onPageChange={handlePageSelect}
      pageCount={pageCount}
      pageRangeDisplayed={pageCount > 20 ? 3 : 2}
      previousLabel={""}
      previousLinkClassName={"paginationLink"}
    />
  );
};

export default Pagination;
