import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Scrollbar from "../Scrollbar.js";
import { PopupContext, StatsContext, TeamsContext } from "../../context.js";
import { getData } from "../../_api";
import { endPointLive } from "../endPoints";
import { pointsFilter, seasonFilter } from "../../utils/fixer";

const getUniqueId = {
  player: "player_name",
  team: "team",
};

const getTeamId = {
  player: "player_team",
  team: "team",
};

const getName = {
  player: "player_name",
  team: "name",
};

const getPoints = {
  player: "total_points",
  team: "total_points_scored",
};

const exceptionalKeys = [
  "created_at",
  "id",
  "picture",
  "player_category",
  "player_id",
  "player_name",
  "player_team",
  "season_name",
  "stats_section",
  "updated_at",
];

const Stats = () => {
  const { popupProps } = useContext(PopupContext);
  const { statsData } = useContext(StatsContext);
  const { teams } = useContext(TeamsContext);

  const { pathname } = useLocation();

  const { statsFor } = useParams();

  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    points: getPoints[statsFor],
    season: "All Seasons",
  });

  const [loading, setLoading] = useState(false);
  const [maxContentWidth, setMaxContentWidth] = useState(0);
  const [pointsType, setPointsType] = useState("Total Points");
  const [statsList, setStatsList] = useState([]);
  const [ranks, setRanks] = useState([]);

  const tBodyRef = useRef(null);

  const handleSelect = ({ target }) => {
    const { name, value } = target;
    setFilters((prev) => ({ ...prev, [name]: value }));

    if (name === "season") filterBySeason(value);
    else if (name === "points")
      setStatsList(getSortedData([...statsList], value));

    const tempPointsType = pointsFilter[statsFor].find(
      (filter) => filter.value === (name === "points" ? value : filters.points)
    )?.text;

    setPointsType(tempPointsType || "Total Points");
  };

  const getStats = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.statsList });
    setLoading(false);
    for (let key in data) {
      if (!data[key].length) return;
    }
    if (data)
      statsData.fn({ player: data.playerstatslist, team: data.teamstatslist });
  };

  const getSortedData = (stats, key = filters.points) =>
    stats.sort((a, b) => {
      const pointsDiff = parseFloat(b[key]) - parseFloat(a[key]);
      if (pointsDiff === 0)
        return (
          parseFloat(a.total_match_played) - parseFloat(b.total_match_played)
        );
      return pointsDiff;
    });

  const addPoints = (stats = statsData.var, teamList = teams) => {
    if (stats[statsFor].length) {
      const aggregatedData = {};

      stats[statsFor].forEach((stats) => {
        const teamId = stats[getTeamId[statsFor]];
        const id = `${stats[getUniqueId[statsFor]]}_${teamId}`;
        if (aggregatedData[id]) {
          Object.keys(stats).forEach((key) => {
            if (
              !exceptionalKeys.includes(key) &&
              !isNaN(parseInt(stats[key]))
            ) {
              aggregatedData[id][key] =
                (parseInt(aggregatedData[id][key]) || 0) + parseInt(stats[key]);
            }
          });
        } else {
          aggregatedData[id] = {
            ...stats,
            season_name: filters.season,
            ...teamList.find((item) => item.id.toString() === teamId),
          };
        }
      });
      let tempStats = Object.values(aggregatedData);

      setStatsList(getSortedData(tempStats));
    }
  };

  const filterBySeason = (seasonName = filters.season) => {
    if (
      seasonName === seasonFilter[0].text &&
      statsData.var[statsFor][0][filters.points]
    )
      addPoints();
    else if (!statsData.var[statsFor][0][filters.points]) {
      setFilters({
        points: getPoints[statsFor],
        season: "All Seasons",
      });
      setPointsType(pointsFilter[statsFor][0].text);
      addPoints();
    } else {
      const aggregatedData = {};

      statsData.var[statsFor].forEach((stats) => {
        if (stats.season_name === seasonName) {
          const id = stats[getUniqueId[statsFor]];
          const teamId = stats[getTeamId[statsFor]];
          const teamSeasonKey = `${id}_${seasonName}`;

          if (aggregatedData[teamSeasonKey] && statsFor === "team") {
            Object.keys(stats).forEach((key) => {
              if (
                !exceptionalKeys.includes(key) &&
                !isNaN(parseInt(stats[key]))
              ) {
                aggregatedData[teamSeasonKey][key] =
                  (parseInt(aggregatedData[teamSeasonKey][key]) || 0) +
                  parseInt(stats[key]);
              }
            });
          } else {
            aggregatedData[
              aggregatedData[teamSeasonKey] ? "teamSeasonKey_2" : teamSeasonKey
            ] = {
              ...stats,
              ...teams.find((item) => item.id.toString() === teamId),
            };
          }
        }
      });

      setStatsList(
        getSortedData(Object.values(aggregatedData), filters.points)
      );
    }
  };

  const onSelectTab = (tab) =>
    navigate(`${pathname.split("stats")[0]}stats/${tab}`);

  const TabContent = () => (
    <Scrollbar loading={loading}>
      <div className="tableOuter">
        <div className="display-shine"></div>
        <div className="display-monitor"></div>
        <div className="display">
          {!!statsList?.length && !loading && (
            <table className="table-responsive table">
              <thead>
                <tr>
                  <th>RANK</th>
                  <th>
                    <span
                      style={{
                        width: maxContentWidth + 20,
                        display: "flex",
                        justifyContent: "center",
                        margin: "0 auto",
                        alignItems: "center",
                      }}
                    >
                      {statsFor.toUpperCase()}
                    </span>
                  </th>
                  <th>Matches Played</th>
                  <th>{pointsType}</th>
                </tr>
              </thead>
              <tbody ref={tBodyRef}>
                {statsList?.map((item, index) =>
                  (statsFor === "player" && index < 10) ||
                  statsFor === "team" ? (
                    <tr key={index}>
                      <td>{`${ranks[index] < 10 ? "0" : ""}${
                        ranks[index]
                      }`}</td>
                      <td>
                        <span
                          style={{
                            width: !!maxContentWidth
                              ? maxContentWidth + 20
                              : "fit-content",
                            display: "flex",
                            margin: "0 auto",
                            textAlign: "left",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ marginRight: 5 }}
                            className="list-icon"
                            src={item.logo}
                            alt=""
                          />
                          {item[getName[statsFor]]}
                        </span>
                      </td>
                      <td>{item.total_match_played}</td>
                      <td>{item[filters.points]}</td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Scrollbar>
  );

  useEffect(() => {
    if (statsList.length) {
      let tempRanks = [1];

      for (let index = 1; index < (statsFor === "player" ? 10 : 12); index++) {
        if (statsList[index])
          tempRanks.push(
            statsList[index][filters.points] ===
              statsList[index - 1][filters.points]
              ? tempRanks[index - 1]
              : tempRanks[index - 1] + 1
          );
      }
      setRanks(tempRanks);
    }
  }, [filters.points, filters.season, statsFor, statsList]);

  useEffect(() => {
    if (ranks.length) {
      setMaxContentWidth(0);
      setTimeout(() => {
        const rows = tBodyRef.current?.childNodes || [];

        let tempMaxWidth = 0;
        for (let ind = 0; ind < rows.length; ind++) {
          if (tempMaxWidth < rows[ind]?.childNodes[1]?.firstChild?.clientWidth)
            tempMaxWidth = rows[ind]?.childNodes[1]?.firstChild?.clientWidth;
        }
        setMaxContentWidth(tempMaxWidth);
      }, 200);
    }
  }, [ranks]);

  useEffect(() => {
    if (!statsData.var) getStats();
    else if (statsData.var && teams) filterBySeason();
    setFilters(({ points, season }) => ({
      points:
        points === "total_points" || points === "total_points_scored"
          ? getPoints[statsFor]
          : points,
      season: season,
    }));

    document.title = `Patna Pirates | ${statsFor} Stats`;
  }, [statsData.var, statsFor]); // eslint-disable-line

  useEffect(() => {
    popupProps.fn((prev) => ({
      ...prev,
      className: "gallerySeasoModal loungeModal fixturesModal",
      heading: "Stats",
      showHeader: true,
    }));
  }, []); // eslint-disable-line

  return (
    <>
      <div className="filterSec">
        <div className="form-group">
          <select
            className="form-select"
            name="points"
            onChange={handleSelect}
            value={filters.points}
          >
            {pointsFilter[statsFor].map(({ text, value }, key) => (
              <option key={key} value={!key ? getPoints[statsFor] : value}>
                {text}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <select
            className="form-select"
            name="season"
            onChange={handleSelect}
            value={filters.season}
          >
            {seasonFilter.map(({ text }, key) => (
              <option key={key}>{text}</option>
            ))}
          </select>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Tabs
            activeKey={statsFor}
            className="mb-3 statsTabs"
            onSelect={onSelectTab}
          >
            <Tab eventKey="player" title="PLAYER">
              {statsFor === "player" && <TabContent />}
            </Tab>
            <Tab eventKey="team" title="TEAM">
              {statsFor === "team" && <TabContent />}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default Stats;
