import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ActionContext, GalleryContext, PopupContext } from "../context.js";
import { galleryHotspots, galleryLinks } from "../utils/fixer";
import Popup from "../components/modal&components/Modal";
import { galleryBg } from "../assets/img";
import { getData } from "../_api/index.js";
import { endPointLive } from "../components/endPoints.js";

const Gallery = () => {
  const { hfAction } = useContext(ActionContext);
  const { popupProps } = useContext(PopupContext);
  const { gallery } = useContext(GalleryContext);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleNavigation = (localLink, OuterLink) => {
    if (localLink) navigate(localLink);
    else if (OuterLink) window.open(OuterLink, "_blank");
    else navigate(hfAction.var);
    hfAction.fn(null);
  };

  const handleClosePopup = () => {
    navigate("/gallery");
    hfAction.fn(null);
    popupProps.fn({});
  };

  const fetchGalleryImages = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.galleryImages });
    setLoading(false);
    if (data) gallery.fn((prev) => ({ ...prev, images: data.reverse() }));
  };

  const fetchGalleryVideos = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.galleryVideos });
    setLoading(false);
    if (data) gallery.fn((prev) => ({ ...prev, videos: data.reverse() }));
  };

  useEffect(() => {
    if (!gallery.var.images.length) fetchGalleryImages();
    if (!gallery.var.videos.length) fetchGalleryVideos();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (pathname.split("/").length - 1 === 1) {
      handleClosePopup();
      document.title = "Patna Pirates | Gallery";
    }
  }, [pathname]); // eslint-disable-line

  useEffect(() => {
    if (hfAction.var) handleNavigation();
  }, [hfAction.var]); // eslint-disable-line

  return (
    <div className="wrapper">
      {pathname.split("/").length - 1 > 1 ? (
        <Popup onClose={handleClosePopup} {...popupProps.var}>
          <Outlet
            context={{
              close: handleClosePopup,
              gallery: gallery.var,
              loading: loading,
            }}
          />
        </Popup>
      ) : (
        <div className="imageOuter galleryPageOuter">
          <img src={galleryBg} alt="" />
          <div className="hotspots">
            {[...galleryHotspots, ...galleryLinks].map((item, index) => (
              <div
                className={`hotspot-item hotspot-item-${index + 1}`}
                key={index}
                name={item.name}
                onClick={() => handleNavigation(item.name, item.link)}
              >
                <Link name={item.name}>
                  <span className="pulsating-circle" name={item.name} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
