import { createContext } from "react";

export const AboutContext = createContext();
export const ActionContext = createContext();
export const FixturesContext = createContext();
export const GalleryContext = createContext();
export const InfoContext = createContext();
export const NewsContext = createContext();
export const PlayersContext = createContext();
export const PopupContext = createContext();
export const ScoreContext = createContext();
export const SponsorContext = createContext();
export const StatsContext = createContext();
export const TeamsContext = createContext();
export const UserContext = createContext();
export const UsersContext = createContext();
