import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Scrollbar from "../../Scrollbar.js/index.js";
import { calendar } from "../../../assets/img/index.js";
import { PopupContext } from "../../../context.js/index.js";

export default function DigitalMedia() {
  const { popupProps } = useContext(PopupContext);

  const { loading, news } = useOutletContext();

  const [currPage, setCurrPage] = useState(0);
  const [paginatedData, paginateData] = useState([]);

  const getDateNTime = (date) => {
    const inputDate = new Date(date);
    const day = inputDate.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      inputDate
    );
    const year = inputDate.getFullYear();
    return `${day} ${month}, ${year}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currPage]);

  useEffect(() => {
    document.title = "Patna Pirates | Digital Media";

    popupProps.fn((prev) => ({
      ...prev,
      className: "gallerySeasoModal",
      heading: "Digital Media",
      showHeader: true,
    }));
  }, []); // eslint-disable-line

  return (
    <>
      <Scrollbar
        loading={loading}
        pagination={{
          data: news,
          dependencies: [news, currPage],
          currPage: currPage,
          setCurrPage: setCurrPage,
          paginateData: paginateData,
          perPage: 12,
        }}
      >
        <div className="row">
          {paginatedData?.map((item, index) => (
            <div className="col-md-6 col-lg-3" key={index}>
              <div className={`season-list-item media-listing digital`}>
                <img src={item.image} className="matchimage" alt="" />
                <h4>
                  {item.name}
                  <span>
                    {" "}
                    <img src={calendar} alt="" /> {getDateNTime(item.datetime)}
                  </span>
                </h4>
                <p> {item.shortdescription} </p>
                <a
                  className="readMore"
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  READ MORE{" "}
                </a>
              </div>
            </div>
          ))}
        </div>
      </Scrollbar>
    </>
  );
}
