import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/esm/CloseButton";
import { closeIcon, whiteArrow } from "../../assets/img";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { ActionContext, PopupContext } from "../../context.js";

const Popup = ({ children, onClose = () => {} }) => {
  const navigate = useNavigate();

  const { showHF } = useContext(ActionContext);
  const { popupProps } = useContext(PopupContext);

  const { pathname } = useLocation();

  const { galleryFor, matchNo, mediaId, season } = useParams();

  const onBack = () => {
    let path = pathname;
    if (matchNo) path = pathname.substring(0, pathname.lastIndexOf("/"));
    else path = `/gallery/season/${season}/${galleryFor}`;

    navigate(`${path}`);
  };

  useEffect(() => {
    showHF(false);
    return () => showHF(true);
  }, []); // eslint-disable-line

  return (
    <Modal
      className={`modal modalPages ${popupProps.var.className || ""}`}
      dialogClassName={`modal-dialog ${popupProps.var.dialogClassName || ""}`}
      onHide={onClose}
      fullscreen={!pathname.includes("user_account")}
      show={pathname.split("/").length - 1 > 1}
    >
      {popupProps.var.showHeader && (
        <Modal.Header>
          {(!!matchNo || !!mediaId) && (
            <button
              className="btn-close"
              style={{ left: 20, margin: 0 }}
              onClick={onBack}
            >
              <img
                src={whiteArrow}
                style={{ transform: "scaleX(-1)" }}
                alt=""
              />
            </button>
          )}
          <CloseButton onClick={onClose}>
            <img src={closeIcon} alt="" />
          </CloseButton>

          {popupProps.var.heading && (
            <Modal.Title>
              <h1>
                <span>{popupProps.var.heading}</span>
              </h1>
            </Modal.Title>
          )}
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default Popup;
