import React from "react";

const PlayerCard = ({
  playerInfo = {},
  handleSelection = () => {},
  selectedPlayers = [],
}) => {
  return (
    <div className="playerCardTeam">
      <div className="imageCol">
        <div className="form-check ">
          <input
            className="form-check-input"
            type="checkbox"
            id="inlineCheckbox1"
            checked={!!selectedPlayers.find((p) => p?.id === playerInfo?.id)}
            onChange={(e) => handleSelection(e, playerInfo)}
          />
          <label className="form-check-label" htmlFor="inlineCheckbox1"></label>
        </div>
        <img src={playerInfo?.player_image} alt="img" />
      </div>
      <div className="detailsCol">
        <div className="playerName">{playerInfo?.player_name}</div>
        <div className="playerDetails">
          <div className="totalRaids">
            <span>{playerInfo?.total_points}</span>
            <p>Total Points</p>
          </div>
          <div className="successfulRaids">
            <span>{playerInfo?.super_10s}</span>
            <p>Super 10s</p>
          </div>
          <div className="superRaids">
            <span>{playerInfo?.super_5s}</span>
            <p>High 5s</p>
          </div>
        </div>
        <div className="playerRole">
          <span
            className={`role ${
              playerInfo?.section === "Allrounder" ||
              playerInfo?.section === "defender"
                ? "selected"
                : ""
            }`}
          >
            {playerInfo?.section?.charAt(0)?.toUpperCase()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlayerCard;
