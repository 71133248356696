import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { PopupContext, UsersContext } from "../../context.js";
import Scrollbar from "../Scrollbar.js";
import { avatarDark } from "../../assets/img/index.js";
import { getData } from "../../_api/index.js";
import { endPointLive } from "../endPoints.js";

const Leaderboard = () => {
  const { popupProps } = useContext(PopupContext);
  const users = useContext(UsersContext);

  const [loading, setLoading] = useState(false);
  const [maxContentWidth, setMaxContentWidth] = useState(0);
  const [ranks, setRanks] = useState([]);
  const [sortedUsers, sortUsers] = useState([]);

  const tBodyRef = useRef(null);

  const handleSortUsers = () => {
    const tempSortedUsers = users.var.sort(
      (a, b) => parseInt(b.total_points) - parseInt(a.total_points)
    );
    sortUsers(tempSortedUsers);
  };

  const getUsers = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.leaderboard });
    setLoading(false);
    if (data?.length) users.fn(data);
  };

  useEffect(() => {
    if (users.var.length) handleSortUsers();
  }, [users.var]); // eslint-disable-line

  useEffect(() => {
    if (sortedUsers.length) {
      let tempRanks = [1];

      for (let index = 1; index < 10; index++) {
        if (sortedUsers[index])
          tempRanks.push(
            sortedUsers[index].total_points ===
              sortedUsers[index - 1].total_points
              ? tempRanks[index - 1]
              : tempRanks[index - 1] + 1
          );
      }
      setRanks(tempRanks);
    }
  }, [sortedUsers]);

  useEffect(() => {
    if (ranks.length) {
      setMaxContentWidth(0);
      setTimeout(() => {
        const rows = tBodyRef?.current?.childNodes || 0;

        let tempMaxWidth = 0;
        for (let ind = 0; ind < rows.length; ind++) {
          if (tempMaxWidth < rows[ind]?.childNodes[1]?.firstChild?.clientWidth)
            tempMaxWidth = rows[ind]?.childNodes[1]?.firstChild?.clientWidth;
        }
        setMaxContentWidth(tempMaxWidth);
      }, 200);
    }
  }, [ranks]);

  useEffect(() => {
    getUsers();

    document.title = `Patna Pirates | Leaderboard`;

    popupProps.fn((prev) => ({
      ...prev,
      className: "gallerySeasoModal loungeModal fixturesModal",
      heading: "Leaderboard",
      showHeader: true,
    }));
  }, []); // eslint-disable-line

  return (
    <Row>
      <Col lg={12}>
        <Scrollbar loading={loading}>
          {!!sortedUsers?.length && (
            <div className="tableOuter">
              <div className="display-shine"></div>
              <div className="display-monitor"></div>
              <div className="display">
                <table className="table-responsive table">
                  <thead>
                    <tr>
                      <th>RANK</th>
                      <th>
                        {" "}
                        <span
                          style={{
                            width: maxContentWidth + 20,
                            display: "flex",
                            justifyContent: "center",
                            margin: "0 auto",
                            alignItems: "center",
                          }}
                        >
                          User
                        </span>
                      </th>
                      <th>Points</th>
                    </tr>
                  </thead>
                  <tbody ref={tBodyRef}>
                    {sortedUsers.map(
                      ({ total_points, user_image, user_name }, index) =>
                        index < 10 && (
                          <tr key={index}>
                            <td>{ranks[index]}</td>
                            <td>
                              <span
                                style={{
                                  width: !!maxContentWidth
                                    ? maxContentWidth + 20
                                    : "fit-content",
                                  display: "flex",
                                  margin: "0 auto",
                                  textTransform: "capitalize",
                                  textAlign: "left",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  alt={user_name}
                                  className="list-icon"
                                  style={{
                                    marginRight: 5,
                                    borderRadius: "50%",
                                  }}
                                  src={user_image || avatarDark}
                                />
                                {user_name}
                              </span>
                            </td>
                            <td>{total_points}</td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Scrollbar>
      </Col>
    </Row>
  );
};

export default Leaderboard;
