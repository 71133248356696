import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context.js/index.js";
import "../createTeam/style.css";
import { getDataWithAuth } from "../../../../_api/index.js";
import { endPointLive } from "../../../endPoints.js";
import PlayerCard2 from "./PlayerCard2.js";

const ViewTeam = ({
  setAction = () => {},
  teamId = "",
  FixtureMatches = [],
  selectedFixtureMatch = {},
  getEditTeamData = () => {},
  isLoading = false,
}) => {
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [dreamTeam, setDreamTeam] = useState({});

  const getTeamData = async (id) => {
    setLoading(true);
    const { data } = await getDataWithAuth({
      url: endPointLive.viewDreamTeam(id),
      token: user.var.token,
    });

    setLoading(false);
    if (data) {
      if (data?.total_score) setTotalScore(data.total_score);
      if (data?.dream_team) setDreamTeam(data.dream_team);
      if (data?.team) setTeams(data?.team);
    }
  };

  useEffect(() => {
    if (teamId) getTeamData(teamId);
  }, [teamId]);

  useEffect(() => {
    document.title = "Patna Pirates | View Team";
  }, []); // eslint-disable-line

  return (
    <>
      <div id="wrapper">
        <div className="p-3">
          <div className="createteamWraper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <h3>Total Points : {totalScore}</h3>
                </div>
                <div className="col-lg-6">
                  <div className="filterSec">
                    <div className="form-group">
                      <select
                        className="form-select"
                        value={selectedFixtureMatch?.id || ""}
                        onMouseDown={(e) => e.preventDefault()}
                        readOnly
                      >
                        <option value="">Select Match</option>
                        {FixtureMatches?.map((matchData, ind) => (
                          <option
                            key={ind}
                            value={matchData?.id}
                          >{`Match No. ${matchData?.match_no}, ${matchData?.location}`}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary viewTeam"
                        disabled={isLoading || loading}
                        onClick={() => {
                          getEditTeamData(teamId);
                        }}
                      >
                        Edit Your Team
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="wintheMatchSec">
                {teams?.id ? (
                  <>
                    <div className="selectTeam">
                      <div className="form-check form-check-inline">
                        <img
                          src={teams?.logo}
                          alt="img"
                          style={{ width: "57px", height: "57px" }}
                        />
                        <input
                          className="form-check-input"
                          type="radio"
                          name="winteam"
                          id="winteam"
                          value="winTeamChoice"
                          checked
                          readOnly
                        />
                        <label className="form-check-label" htmlFor="winteam">
                          {teams?.name}
                        </label>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="row">
                  {dreamTeam?.playersdata?.map((player, ind) => (
                    <div className="col-lg-6 col-xl-4" key={ind}>
                      <PlayerCard2 playerInfo={player} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTeam;
