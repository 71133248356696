import { useEffect, useRef, useState } from "react";
import { menuIcons } from "../assets/img";
import { footerMenu } from "../utils/fixer";
import useWindowDimensions from "./dimensions";

const Footer = ({ action }) => {
  const { width } = useWindowDimensions();
  const [slideCount, countSlide] = useState(0);
  const [isFooterShown, showFooter] = useState(true);
  const [slideButtons, setSlideButtons] = useState({
    left: false,
    right: true,
  });

  const footerRef = useRef(null);
  const navRef = useRef(null);

  const urls = {
    tickets:
      "https://in.bookmyshow.com/sports/pro-kabaddi-league-season-11-2024/ET00414457",
    merchandise: "https://paceinternational.in/collection/DlzGzVGY",
  };

  const redirect = (urlFor) => {
    window.open(urls[urlFor], "_blank");
  };

  const handleSliderButtons = () => {
    setSlideButtons({
      left: navRef.current.scrollLeft !== 0,
      right:
        Math.ceil(navRef.current.scrollLeft + width) !==
        navRef.current.scrollWidth,
    });
  };

  const slideLeft = () => {
    navRef.current.scrollLeft =
      navRef.current?.scrollLeft +
      navRef.current?.childNodes[0]?.childNodes[slideCount]?.scrollWidth;
    countSlide((prev) => prev + 1);

    handleSliderButtons();
  };

  const slideRight = () => {
    navRef.current.scrollLeft =
      navRef.current?.scrollLeft -
      navRef.current?.childNodes[0]?.childNodes[slideCount]?.scrollWidth;
    countSlide((prev) => prev - 1);

    handleSliderButtons();
  };

  const handleFooter = () => {
    const footer = footerRef.current;

    footer.style.transform = `translateY(${
      isFooterShown ? footer.clientHeight : 0
    }px)`;
    setTimeout(() => showFooter((prev) => !prev), 900);
  };

  const onLoad = () => {
    if (width > 1139) return;
    const navInterval = setInterval(() => {
      if (navRef.current) {
        navRef.current.addEventListener("scroll", handleSliderButtons, false);
        clearInterval(navInterval);
      }
    }, 50);

    return () => {
      window.removeEventListener("scroll", handleSliderButtons);
      clearInterval(navInterval);
    };
  };

  useEffect(onLoad, []); // eslint-disable-line

  return (
    <footer className="footer" ref={footerRef}>
      <button
        type="button"
        className={`footer-collapse${!isFooterShown ? " rotate" : ""}`}
        onClick={handleFooter}
        style={isFooterShown ? {} : { top: -24 }}
      />
      {width < 1140 && (
        <>
          {slideButtons.left && (
            <button className="slider-btn left" onClick={slideRight} />
          )}
          {slideButtons.right && (
            <button className="slider-btn right" onClick={slideLeft} />
          )}
        </>
      )}
      <nav ref={navRef}>
        <ul>
          {footerMenu.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                if (item.name === "merchandise" || item.name === "tickets")
                  redirect(item.name);
                else action(item.name);
              }}
              name={item.name}
            >
              <span>
                <img
                  src={menuIcons[index]}
                  style={item.name === "tickets" ? { height: 27 } : {}}
                  alt=""
                />
              </span>
              {item.title}
              {item.icon}
            </li>
          ))}
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
