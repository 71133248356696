import React from "react";

const Input = ({ disabled = false, inputProps, isFormValidated }) => (
  <div className="form-floating">
    {inputProps.type === "textarea" ? (
      <textarea
        className={`form-control${
          isFormValidated
            ? !!inputProps.error
              ? " is-invalid"
              : " is-valid"
            : ""
        }`}
        disabled={disabled}
        autoComplete={"off"}
        {...inputProps}
        type={inputProps.type || "text"}
      />
    ) : (
      <input
        className={`form-control${
          isFormValidated
            ? !!inputProps.error
              ? " is-invalid"
              : " is-valid"
            : ""
        }`}
        disabled={disabled}
        autoComplete={"off"}
        {...inputProps}
        type={inputProps.type || "text"}
      />
    )}
    <label {...inputProps.label}>{inputProps.label.text}</label>
    {!!inputProps.error && (
      <span style={{ color: "red", fontSize: 14 }}>{inputProps.error}</span>
    )}
  </div>
);

export default Input;
