import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Scrollbar from "../../Scrollbar.js/index.js";
import LightboxComponent from "../Lightbox.js";
import { viewIcon } from "../../../assets/img/index.js";
import { PopupContext } from "../../../context.js/index.js";

const MediaCoverage = () => {
  const { popupProps } = useContext(PopupContext);

  const { loading, news } = useOutletContext();

  const { photoIndex, season } = useParams();

  const [filteredData, filterData] = useState([]);

  const navigate = useNavigate();

  const [currPage, setCurrPage] = useState(0);
  const [images, setImages] = useState([]);
  const [paginatedData, paginateData] = useState([]);

  const setMediaIndex = (ind) => {
    if (ind !== null) navigate(`/news_events/media_coverage/${season}/${ind}`);
    else navigate(`/news_events/media_coverage/${season}`);
  };

  useLayoutEffect(() => {
    if (news) {
      filterData(
        news.filter(
          ({ season_name }) => season_name === `Season ${season.split("_")[1]}`
        )
      );
      setCurrPage(0);
    }
  }, [season, news]);

  useEffect(() => {
    if (paginatedData?.length) {
      setImages(paginatedData.map(({ image }) => image));
    }
  }, [paginatedData]);

  useEffect(() => {
    document.title = "Patna Pirates | Media Coverage";

    popupProps.fn((prev) => ({
      ...prev,
      className: "gallerySeasoModal",
      heading: "Print Media",
      showHeader: true,
    }));
  }, []); // eslint-disable-line

  return (
    <>
      <Scrollbar
        loading={loading}
        pagination={{
          data: filteredData,
          dependencies: [filteredData, currPage],
          currPage: currPage,
          setCurrPage: setCurrPage,
          paginateData: paginateData,
        }}
      >
        <div className="row">
          {paginatedData?.map((item, index) => (
            <div className="col-md-6 col-lg-4" key={index}>
              <div className={`season-list-item media-listing `}>
                <img src={item.image} className="matchimage" alt="" />

                <div className="overlayList">
                  <h3>{item.date_time}</h3>
                  <Link
                    className="view icon"
                    to={`/news_events/media_coverage/${season}/${index}`}
                  >
                    <img src={viewIcon} alt="" />{" "}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Scrollbar>
      <LightboxComponent
        index={photoIndex !== null ? parseInt(photoIndex) : photoIndex}
        setIndex={setMediaIndex}
        media={images}
      />
    </>
  );
};

export default MediaCoverage;
