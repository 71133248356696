import React, { useContext, useEffect, useState } from "react";
import { PopupContext, UserContext } from "../../../../context.js";
import "./style.css";
import PlayerCard from "./PlayerCard.js";
import { getDataWithAuth, postDataWithAuth } from "../../../../_api/index.js";
import { endPointLive } from "../../../endPoints.js";
import { toast } from "react-toastify";
import ViewTeam from "./ViewTeam.js";

const CreateTeam = () => {
  const { popupProps } = useContext(PopupContext);
  const { user } = useContext(UserContext);

  const [winTeamChoice, setWinTeamChoice] = useState("");
  const [selectedPlayers, selectPlayers] = useState([]);
  const [playersData, setPlayersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [matches, setMatches] = useState([]);
  const [prevMatches, setPrevMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState({});
  const [teams, setTeams] = useState([]);
  const [action, setAction] = useState("create");
  const [teamId, setTeamId] = useState("");
  const [isPrevMatchSelected, setIsPrevMatchSelected] = useState(false);

  const handlePlayerSelection = (e, player) => {
    let playerId = player?.id;
    if (e.target.checked) {
      selectPlayers((prev) => [...prev, player]);
    } else {
      selectPlayers((prev) => prev.filter((obj) => obj?.id !== playerId));
    }
  };

  const getTeamData = async () => {
    setLoading(true);
    const { data } = await getDataWithAuth({
      url: endPointLive.getCreateTeamData,
      token: user.var.token,
    });

    setLoading(false);
    if (data) {
      if (data?.players?.length) setPlayersData(data.players);
      if (data?.fixmatches?.length) setMatches(data.fixmatches);
      if (data?.pre_fixmatches?.length) setPrevMatches(data.pre_fixmatches);
    }
  };

  const getEditTeamData = async (id) => {
    setLoading(true);
    const { success, message, data } = await getDataWithAuth({
      url: endPointLive.editDreamTeamData(id),
      token: user.var.token,
    });

    setLoading(false);
    if (success && data) {
      setAction("edit");
      if (data?.all_team?.length) setPlayersData(data.all_team);
      const splayerids = data?.selected_team?.teamplayer?.split(",");
      let arr = [];
      for (let i = 0; i < splayerids?.length; i++) {
        let obj = data?.all_team?.find((p) => p?.id === Number(splayerids[i]));
        arr.push(obj);
      }
      selectPlayers(arr);
      if (data?.win_match) setWinTeamChoice(data.win_match?.id);
    } else if (message) {
      toast.error(message);
    }
  };

  const handleSelectChange = (event) => {
    let selectedOption = matches.find(
      (option) => option.id === Number(event.target.value)
    );
    if (!selectedOption) {
      selectedOption = prevMatches.find(
        (option) => option.id === Number(event.target.value)
      );
      if (selectedOption) {
        setIsPrevMatchSelected(true);
        setTeams([]);
        setWinTeamChoice("");
        selectPlayers([]);
        toast.info("Match Ended");
      }
    } else if (selectedOption?.id) {
      getMatch(selectedOption?.team_name);
      setIsPrevMatchSelected(false);
    }

    setSelectedMatch(selectedOption);
  };

  const getMatch = async (id) => {
    setLoading(true);
    const { success, data } = await getDataWithAuth({
      url: endPointLive.getFixtureMatch(id),
      token: user.var.token,
    });

    setLoading(false);
    if (success && data) {
      setTeams(data);
      selectPlayers([]);
      let teamPatnaPirate = data?.find((team) => team?.id === 2);
      if (teamPatnaPirate?.id) setWinTeamChoice(teamPatnaPirate.id);
    }
  };

  const viewDreamTeam = async () => {
    if (!selectedMatch?.id) {
      toast.info("Please select match");
      return;
    }
    setLoading(true);
    const { success, data, message } = await getDataWithAuth({
      url: endPointLive.checkDreamTeam(selectedMatch?.id, user.var.id),
      token: user.var.token,
    });

    setLoading(false);
    if (success && data) {
      setTeamId(data);
      setAction("view");
    }
    if (!success && message) toast.error(message);
  };

  const handleSubmit = async () => {
    if (!loading && validateData()) {
      let playerIds = selectedPlayers?.map((player) => player.id);

      const body = new FormData();
      body.append("user_id", user.var.id);
      body.append("season_name", selectedMatch?.season_name);
      body.append("fixmatches_id", selectedMatch?.id);
      body.append("teamplayer", playerIds);
      body.append("win_match", winTeamChoice);
      body.append("date", selectedMatch?.date_time);

      if (teamId) body.append("id", teamId);

      setLoading(true);

      const { success, team_id, message } = await postDataWithAuth({
        body,
        url: teamId
          ? endPointLive.updateDreamTeam
          : endPointLive.createDreamTeam,
        token: user.var.token,
      });
      setLoading(false);
      if (teamId && success) toast.success("Team Updated Successfully");
      else if (success && team_id) {
        setTeamId(team_id);
        toast.success("Team Created Successfully");
        setAction("view");
      }
      if (!success && message) toast.error(message);
    }
  };

  const validateData = () => {
    let validate = true;

    if (!selectedMatch?.id) {
      toast.error("Please select match");
      validate = false;
    } else if (isPrevMatchSelected) {
      toast.error("Match ended");
      validate = false;
    } else if (!winTeamChoice) {
      toast.error("Please select who will win the match");
      validate = false;
    } else if (selectedPlayers?.length !== 7) {
      toast.error("Please select 7 players");
      validate = false;
    } else if (selectedPlayers?.length === 7) {
      let rc = 0,
        dc = 0;
      for (let i = 0; i < selectedPlayers?.length; i++) {
        if (selectedPlayers[i]?.section === "Allrounder") {
          dc++;
          rc++;
        } else if (selectedPlayers[i]?.section === "defender") dc++;
        else rc++;
      }
      if (!(rc >= 3 && dc >= 4)) {
        toast.info("Please select 4 defenders and 3 raiders");
        validate = false;
      }
    }
    return validate;
  };

  useEffect(() => {
    document.title = "Patna Pirates | Create Team";

    popupProps.fn((prev) => ({
      ...prev,
      className: "gallerySeasoModal mbp-0 createTeamModal",
      heading: "Create Your Team",
      showHeader: true,
    }));
    setAction("create");
    getTeamData();
  }, []); // eslint-disable-line

  return (
    <>
      {action === "view" ? (
        <ViewTeam
          setAction={setAction}
          teamId={teamId}
          FixtureMatches={matches}
          selectedFixtureMatch={selectedMatch}
          getEditTeamData={getEditTeamData}
          isLoading={loading}
        />
      ) : (
        <div id="wrapper">
          <div className="p-3">
            <div className="createteamWraper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6">
                    <h3>Select 7 Players</h3>
                  </div>
                  <div className="col-lg-6">
                    <div className="filterSec">
                      <div className="form-group">
                        <select
                          className="form-select"
                          value={selectedMatch?.id || ""}
                          onChange={(e) => handleSelectChange(e)}
                          disabled={loading}
                        >
                          <option value="">Select Match</option>
                          {prevMatches.map((matchData, ind) => (
                            <option
                              key={`p-${ind}`}
                              value={matchData?.id}
                            >{`Match No. ${matchData?.match_no}, ${matchData?.location}`}</option>
                          ))}
                          {matches.map((matchData, ind) => (
                            <option
                              key={ind}
                              value={matchData?.id}
                            >{`Match No. ${matchData?.match_no}, ${matchData?.location}`}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <button
                          className="btn btn-primary viewTeam"
                          onClick={viewDreamTeam}
                        >
                          View Your Team
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wintheMatchSec">
                  {teams.length > 1 ? (
                    <>
                      <h4>Who Will Win The Match</h4>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="selectTeam">
                          <div className="form-check form-check-inline">
                            <img
                              src={teams[0]?.logo}
                              alt="img"
                              style={{ width: "57px", height: "57px" }}
                            />
                            <input
                              className="form-check-input"
                              type="radio"
                              name="winTeamChoice"
                              id="winTeamChoice1"
                              value={winTeamChoice}
                              checked={winTeamChoice === teams[0]?.id}
                              onChange={() => {
                                setWinTeamChoice(teams[0]?.id);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="winTeamChoice1"
                            >
                              {teams[0]?.name}
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <img
                              src={teams[1]?.logo}
                              alt="img"
                              style={{ width: "57px", height: "57px" }}
                            />
                            <input
                              className="form-check-input"
                              type="radio"
                              name="winTeamChoice"
                              id="winTeamChoice2"
                              value={winTeamChoice}
                              checked={winTeamChoice === teams[1]?.id}
                              onChange={() => {
                                setWinTeamChoice(teams[1]?.id);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="winTeamChoice2"
                            >
                              {teams[1]?.name}
                            </label>
                          </div>
                        </div>
                        <div>
                          <h3>{`${selectedPlayers?.length} Player Selected`}</h3>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="row">
                    {playersData.map((player, ind) => (
                      <div className="col-lg-6 col-xxl-4" key={ind}>
                        <PlayerCard
                          playerInfo={player}
                          handleSelection={handlePlayerSelection}
                          selectedPlayers={selectedPlayers}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateTeam;
