import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Scrollbar from "../Scrollbar.js/index.js";
import { playIcon, rightArrow } from "../../assets/img/index.js";
import { Tab, Tabs } from "react-bootstrap";
import LightboxComponent from "./Lightbox.js";
import { PopupContext } from "../../context.js/index.js";

const GalleryMedia = () => {
  const navigate = useNavigate();

  const { popupProps } = useContext(PopupContext);

  const { gallery, loading } = useOutletContext();

  const { galleryFor, mediaId, photoIndex, season } = useParams();

  const [activeVideo, activateVideo] = useState(null);
  const [currPage, setCurrPage] = useState(0);
  const [currPage2, setCurrPage2] = useState(0);
  const [data, setData] = useState({ images: [], videos: [] });
  const [paginatedData, paginateData] = useState([]);
  const [paginatedData2, paginateData2] = useState([]);
  const [matchImages, setMatchImages] = useState(null);
  const [minFrameHeight, setMinFrameHeight] = useState(null);

  const filterData = () => {
    const tempData = {
      images:
        gallery.images.filter((item) => item.season.split(" ")[1] === season) ||
        [],
      videos: gallery.videos.filter((item) => item.season === season) || [],
    };
    setData(tempData);

    popupProps.fn((prev) => ({
      ...prev,
      className: "gallerySeasoModal",
      heading: `Season ${season}`,
      showHeader: true,
    }));
  };

  const setMediaIndex = (ind) => {
    if (ind !== null)
      navigate(`/gallery/season/${season}/images/${mediaId}/${ind}`);
    else navigate(`/gallery/season/${season}/images/${mediaId}`);
  };

  useEffect(filterData, [gallery[galleryFor]]); // eslint-disable-line

  useLayoutEffect(() => {
    if (mediaId && data.images.length && galleryFor)
      setMatchImages(data.images[mediaId].all_images.map(({ image }) => image));
    else setMatchImages(null);
  }, [mediaId, data.images, galleryFor]); // eslint-disable-line

  useEffect(() => {
    document.title = `Patna Pirates | Gallery - Season ${season}`;
  }, []); // eslint-disable-line

  const media = {
    images: matchImages,
    videos: data.videos,
  };

  const getEmbedUrl = (url, index) => {
    let embedUrl = url;
    if (!url) {
      console.log(`no video url for video - ${index}`);
    } else if (url.includes("watch?v=")) {
      embedUrl = url.replace("watch?v=", "embed/");
      if (embedUrl.includes("&")) embedUrl = embedUrl.split("&")[0];
    }
    if (embedUrl.includes("?")) embedUrl = embedUrl.split("?")[0];

    return embedUrl;
  };

  return (
    <>
      {!mediaId && data[galleryFor]?.length ? (
        <Tabs
          activeKey={galleryFor}
          className="mb-3 jc-fs"
          onSelect={(tab) => {
            activateVideo(null);
            navigate(`/gallery/season/${season}/${tab}`);
          }}
        >
          <Tab eventKey="images" title="PHOTO GALLERY">
            {galleryFor === "images" && (
              <Scrollbar
                loading={loading}
                pagination={{
                  data: data[galleryFor],
                  dependencies: [data[galleryFor], currPage, galleryFor],
                  currPage: currPage,
                  paginateData: paginateData,
                  perPage: 9,
                  setCurrPage: setCurrPage,
                }}
              >
                <div className="row">
                  {paginatedData.map(({ image, title }, key) => (
                    <div className="col-md-6 col-lg-4" key={key}>
                      <div className="season-list-item">
                        <img src={image} className="matchimage" alt="" />
                        {title?.includes(":") && (
                          <div className="matchNumb">
                            {title?.split(":")[0]}
                          </div>
                        )}
                        <div className="overlayList">
                          <h3>{title}</h3>
                          <Link className="view" to={`${key}`}>
                            View <img src={rightArrow} alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Scrollbar>
            )}
          </Tab>
          <Tab eventKey="videos" title="VIDEO GALLERY">
            {galleryFor === "videos" && (
              <Scrollbar
                loading={loading}
                pagination={{
                  data: data[galleryFor],
                  dependencies: [data[galleryFor], currPage, galleryFor],
                  currPage: currPage,
                  paginateData: paginateData,
                  perPage: 9,
                  setCurrPage: setCurrPage,
                }}
              >
                <div className="row">
                  {paginatedData.map(
                    ({ video_url }, key) =>
                      video_url && (
                        <div className="col-md-6 col-lg-4" key={key}>
                          <div className="thumbOuter">
                            {video_url.includes("iframe") ? (
                              <span
                                className="video"
                                dangerouslySetInnerHTML={{
                                  __html: video_url,
                                }}
                              />
                            ) : activeVideo === key ? (
                              <iframe
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={true}
                                src={`${getEmbedUrl(
                                  video_url,
                                  key
                                )}?autoplay=1&mute=1}`}
                                title={`video-${key + 1}`}
                                style={{ height: minFrameHeight }}
                              />
                            ) : (
                              <img
                                alt=""
                                src={`https://img.youtube.com/vi/${
                                  getEmbedUrl(video_url, key)?.split("/")[
                                    getEmbedUrl(video_url, key)?.split("/")
                                      .length - 1
                                  ]
                                }/hqdefault.jpg`}
                                style={{ maxHeight: 340 }}
                                onLoad={({ target }) => {
                                  if (!minFrameHeight)
                                    setMinFrameHeight(target.height);
                                }}
                              />
                            )}
                            {activeVideo !== key &&
                              !video_url.includes("iframe") && (
                                <span
                                  className="playIcon"
                                  onClick={() => activateVideo(key)}
                                >
                                  <img src={playIcon} alt="" />
                                </span>
                              )}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </Scrollbar>
            )}
          </Tab>
        </Tabs>
      ) : media[galleryFor]?.length ? (
        <Scrollbar
          loading={loading}
          pagination={{
            data: media[galleryFor],
            dependencies: [media[galleryFor], currPage2],
            currPage: currPage2,
            paginateData: paginateData2,
            perPage: 9,
            setCurrPage: setCurrPage2,
          }}
        >
          <div className="row">
            {paginatedData2?.map((item, key) => (
              <Link className="col-md-6 col-lg-4" key={key} to={`${key}`}>
                <div className="season-list-item">
                  <img src={item} className="matchimage" alt="" />
                </div>
              </Link>
            ))}
          </div>
        </Scrollbar>
      ) : null}
      <LightboxComponent
        index={photoIndex !== null ? parseInt(photoIndex) : photoIndex}
        setIndex={setMediaIndex}
        media={media ? media[galleryFor] : null}
      />
    </>
  );
};
export default GalleryMedia;
