import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Scrollbar from "../Scrollbar.js";
import {
  AboutContext,
  InfoContext,
  PopupContext,
} from "../../context.js/index.js";
import Slider from "react-slick";
import { aboutBanner, closeIcon, rightArrow } from "../../assets/img";
import { aboutSocialLinks, aboutText } from "../../utils/fixer.js";
import { getData } from "../../_api/index.js";
import { endPointLive } from "../endPoints.js";
import ButtonLoder from "../ButtonLoader.js";

const About = () => {
  const { close } = useOutletContext();

  const info = useContext(InfoContext);
  const { popupProps } = useContext(PopupContext);
  const { teamStanding } = useContext(AboutContext);

  const [isVideoPlayed, playVideo] = useState(false);
  const [loading, setLoading] = useState(false);

  const stopVideo = () => playVideo(false);

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <img src={rightArrow} alt="next" />
      </button>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <img src={rightArrow} alt="next" />
      </button>
    );
  }

  var settings = {
    arrows: true,
    autoplay: false,
    autoplaySpeed: 100,
    centerMode: false,
    dots: false,
    draggable: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 3,
    speed: 1000,
    // autoplaySpeed: 100,

    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getTeamStanding = async () => {
    setLoading(true);
    const { data } = await getData({ url: endPointLive.teamStanding });
    setLoading(false);
    if (data?.length) {
      const tempData = data.filter((_, index) => index > 1);

      teamStanding.fn([...tempData, data[0], data[1]]);
    }
  };

  useEffect(() => {
    if (!teamStanding?.var?.length) getTeamStanding();
  }, [teamStanding.var]); // eslint-disable-line

  useEffect(() => {
    document.title = "Patna Pirates | About";
    popupProps.fn((prev) => ({
      ...prev,
      className: "aboutModal",
      showHeader: false,
    }));
  }, []); // eslint-disable-line

  return (
    <>
      <button
        type="button"
        className="btn-close"
        onClick={isVideoPlayed ? stopVideo : close}
      >
        <img src={closeIcon} alt="" />
      </button>

      <Scrollbar>
        <div className="p-3">
          <div className="about-text">
            <div className="row">
              <div className="col-lg-5 col-xxl-4">
                <img src={aboutBanner} alt="" className="aboutBanner" />
              </div>
              <div className="col-lg-7 col-xxl-8">
                <div
                  className="modal-header pt-0"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h1 className="modal-title">
                    <span>About Patna Pirates</span>
                  </h1>
                </div>

                <div>
                  {aboutText.first.map((text, key) => (
                    <p dangerouslySetInnerHTML={{ __html: text }} key={key} />
                  ))}
                </div>
              </div>
            </div>
            {aboutText.second.map((text, key) => (
              <p dangerouslySetInnerHTML={{ __html: text }} key={key} />
            ))}
          </div>

          <div className="seasonWinners">
            <h2>Meet The Pirates</h2>
            <div className="playIcon">
              <span
                className="video-play-button"
                onClick={() => playVideo(true)}
              >
                <span />
              </span>
            </div>
          </div>

          {!!info && (
            <div className="followonSocails">
              <div className="row">
                {aboutSocialLinks.map(
                  ({ icon, followers, link, text }, key) => (
                    <div className="col-md-6 col-lg-3" key={key}>
                      <a
                        href={info[link]}
                        target="_blank"
                        rel="noreferrer"
                        className="followUsOn"
                      >
                        <span className="socailIcons">
                          <img src={icon} alt={link} />
                        </span>
                        <div>
                          <div className="count">{followers}</div>
                          <p>{text}</p>
                        </div>
                      </a>
                    </div>
                  )
                )}
              </div>
            </div>
          )}

          <div className="teamStanding">
            <div className="title">
              <h2 className="modal-title  headingMain">
                <span>Team Standing</span>
              </h2>
            </div>

            <div className="row">
              <Slider {...settings}>
                {teamStanding.var?.map(({ image, match_status, name }, key) => (
                  <div className="col-md-6 col-lg-3" key={key}>
                    <div
                      className="seasonPostion"
                      style={{
                        margin: "0 15px",
                        minHeight: loading ? 120 : "unset",
                      }}
                    >
                      {loading ? (
                        <ButtonLoder />
                      ) : (
                        <>
                          <picture>
                            <img
                              src={image}
                              alt=""
                              style={{ minHeight: 110 }}
                            />
                          </picture>
                          <h3>{name}</h3>
                          <p
                            style={{ minHeight: 60, textAlign: "center" }}
                            dangerouslySetInnerHTML={{ __html: match_status }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </Scrollbar>
      {isVideoPlayed && (
        <div className="about-video">
          <iframe
            src="https://player.vimeo.com/video/866650982?autoplay=1"
            title="patna Pirates"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen={true}
          />
        </div>
      )}
    </>
  );
};

export default About;
