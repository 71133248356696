import { useEffect, useRef } from "react";
import Pagination from "../Pagination";
import { itemsPerPage } from "../../utils/fixer";

const Scrollbar = ({
  children,
  pagination = {},
  loading = false,
  color,
  minHeight,
}) => {
  let {
    data,
    dependencies,
    currPage,
    setCurrPage,
    paginateData,
    perPage = itemsPerPage,
  } = pagination;

  const loadingElemRef = useRef(null);
  const loadingRef = useRef(null);

  const animateOnLoad = () => {
    loadingRef.current = setInterval(() => {
      const elem = loadingElemRef.current;
      if (elem) {
        const loadingChild = document.createElement("span");
        if (elem.childNodes.length === 4)
          while (elem.firstChild) elem.removeChild(elem.firstChild);

        if (color && elem.childNodes.length % 2 === 0)
          loadingChild.style.background = color;
        elem.appendChild(loadingChild);
      }
    }, 500);
  };

  const handlePageSelect = ({ selected: page }) => {
    setCurrPage(page);
  };

  const handlePagination = () => {
    if (data && paginateData) {
      if (data.length <= perPage) paginateData(data);
      else {
        paginateData(
          data?.filter(
            (_, index) =>
              index >= currPage * perPage &&
              index < currPage * perPage + perPage
          )
        );
      }
    } else if (paginateData) paginateData([]);
  };

  useEffect(handlePagination, dependencies); // eslint-disable-line

  useEffect(() => {
    if (loading) {
      if (!loadingRef.current) animateOnLoad();
    } else clearInterval(loadingRef.current);
  }, [loading]); // eslint-disable-line

  return (
    <>
      {loading ? (
        <div className="my-loading" style={minHeight ? { minHeight } : {}}>
          <div ref={loadingElemRef} />
        </div>
      ) : (
        children
      )}
      {data?.length > perPage && (
        <Pagination
          currPage={currPage}
          items={data}
          handlePageSelect={handlePageSelect}
          perPage={perPage}
        />
      )}
    </>
  );
};

export default Scrollbar;
