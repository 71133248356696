import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { bgVideo } from "../assets/videos";
import { ActionContext, PopupContext } from "../context.js";
import Popup from "../components/modal&components/Modal";
import { rotateIcon } from "../assets/img";
import useWindowDimensions from "../components/dimensions";

const Home = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { width } = useWindowDimensions();

  const { popupProps } = useContext(PopupContext);
  const { showHF } = useContext(ActionContext);

  const handleClosePopup = () => {
    popupProps.fn({});
  };

  useEffect(() => {
    document.title = "Patna Pirates | Home";
    popupProps.fn((prev) => ({ ...prev, show: true, showHeader: true }));
    showHF(false);
    return () => showHF(true);
  }, []); // eslint-disable-line

  return (
    <div>
      <div className="wrapper">
        <div className="videoOuter">
          <video
            controls={false}
            autoPlay={true}
            onEnded={() => navigate("dashboard")}
            muted
            // loop
          >
            <source src={bgVideo} type="video/mp4" />
          </video>

          <button
            type="button"
            className="btn skip"
            onClick={() => navigate("dashboard")}
          >
            SKIP
          </button>
        </div>
      </div>
      {width < 584 && pathname.split("/").length - 1 > 1 && (
        <Popup onClose={handleClosePopup}>
          <div id="warning-message">
            <div>
              <img src={rotateIcon} alt="rotate" />
              <p>
                This website is best viewable in landscape mode. Please rotate
                your phone for the best experience.
              </p>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default Home;
