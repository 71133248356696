import React from "react";

const PlayerCard2 = ({ playerInfo = {} }) => {
  return (
    <div className="playerCardTeam playerPoints">
      <div className="imageCol">
        <img src={playerInfo?.player_image} alt="img" />
      </div>
      <div className="detailsCol">
        <div className="playerName">{playerInfo?.player_name}</div>
        <div className="playerDetails">
          <div className="superRaids">
            <span>{playerInfo?.total_points}</span>
            <p>Points</p>
          </div>
          <div className="superRaids">
            <div className="playerRole">
              <span
                className={`role ${
                  playerInfo?.section === "Allrounder" ||
                  playerInfo?.section === "defender"
                    ? "selected"
                    : ""
                }`}
              >
                {playerInfo?.section?.charAt(0)?.toUpperCase()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerCard2;
