import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AboutContext,
  ActionContext,
  FixturesContext,
  GalleryContext,
  InfoContext,
  NewsContext,
  PlayersContext,
  PopupContext,
  ScoreContext,
  SponsorContext,
  StatsContext,
  TeamsContext,
  UserContext,
  UsersContext,
} from "./context.js";
import { commonRoutes } from "./routes";
import { useEffect, useRef, useState } from "react";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import { getData } from "./_api/index.js";
import { endPointLive } from "./components/endPoints.js";

function App() {
  const toastRef = useRef(null);

  const [gallery, setGallery] = useState({ images: [], videos: [] });
  const [fixtures, setFixtures] = useState(null);
  const [hfAction, setHfAction] = useState(null);
  const [info, setInfo] = useState(null);
  const [isHFShown, showHF] = useState(true);
  const [news, setNews] = useState({
    digitalMedia: null,
    mediaCoverages: null,
  });
  const [players, setPlayers] = useState(null);
  const [popupProps, setPopupProps] = useState({});
  const [scoreList, setScoreList] = useState(null);
  const [sponsors, setSponsors] = useState([]);
  const [statsData, setStatsData] = useState(null);
  const [teams, setTeams] = useState(null);
  const [teamStanding, setTeamStanding] = useState([]);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user") || "{}")
  );
  const [users, setUsers] = useState([]);

  const handleHfAction = (page) => setHfAction(page);

  const getInfo = async () => {
    const { data } = await getData({ url: endPointLive.info });
    if (data?.id) setInfo(data);
  };

  const getPlayers = async () => {
    const { data } = await getData({ url: endPointLive.playerList });
    if (data?.length) {
      const coaches = [];
      const players = [];
      data.forEach((player) => {
        if (player.section.toLowerCase().includes("coach"))
          coaches.push(player);
        else players.push(player);
      });
      setPlayers([...coaches, ...players]);
    }
  };

  const getTeams = async () => {
    const { data } = await getData({ url: endPointLive.teamList });
    if (data?.length) setTeams(data);
  };

  const getRoutes = (routes = commonRoutes) => {
    return routes.map((route, key) => (
      <Route
        key={key}
        {...route}
        element={
          user.token && route.path === "user_account/:account" ? (
            <Navigate to="/dashboard/user_profile" />
          ) : !user.token &&
            (route.path === "user_profile" ||
              route.path.includes("members_lounge")) ? (
            <Navigate to="/dashboard/user_account/login" />
          ) : (
            route.element
          )
        }
      >
        {!!route.children ? getRoutes(route.children) : null}
      </Route>
    ));
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    getInfo();
    getPlayers();
    getTeams();

    const handleKeyDown = (event) => {
      if (event.keyCode === 27) event.preventDefault();
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={true}
        limit={3}
        ref={toastRef}
      />
      {!!isHFShown && !!toastRef.current && (
        <Header action={handleHfAction} user={user} textColor={isHFShown} />
      )}
      <BrowserRouter>
        <AboutContext.Provider
          value={{ teamStanding: { var: teamStanding, fn: setTeamStanding } }}
        >
          <ActionContext.Provider
            value={{ hfAction: { var: hfAction, fn: setHfAction }, showHF }}
          >
            <FixturesContext.Provider
              value={{ fixtures: { var: fixtures, fn: setFixtures } }}
            >
              <GalleryContext.Provider
                value={{ gallery: { var: gallery, fn: setGallery } }}
              >
                <InfoContext.Provider value={info}>
                  <NewsContext.Provider
                    value={{ news: { var: news, fn: setNews } }}
                  >
                    <PlayersContext.Provider value={{ players: players }}>
                      <PopupContext.Provider
                        value={{
                          popupProps: { var: popupProps, fn: setPopupProps },
                        }}
                      >
                        <ScoreContext.Provider
                          value={{
                            scoreList: { var: scoreList, fn: setScoreList },
                          }}
                        >
                          <SponsorContext.Provider
                            value={{
                              sponsors: { var: sponsors, fn: setSponsors },
                            }}
                          >
                            <StatsContext.Provider
                              value={{
                                statsData: {
                                  var: statsData,
                                  fn: setStatsData,
                                },
                              }}
                            >
                              <TeamsContext.Provider value={{ teams: teams }}>
                                <UserContext.Provider
                                  value={{ user: { var: user, fn: setUser } }}
                                >
                                  <UsersContext.Provider
                                    value={{ var: users, fn: setUsers }}
                                  >
                                    <Routes>{getRoutes()}</Routes>
                                  </UsersContext.Provider>
                                </UserContext.Provider>
                              </TeamsContext.Provider>
                            </StatsContext.Provider>
                          </SponsorContext.Provider>
                        </ScoreContext.Provider>
                      </PopupContext.Provider>
                    </PlayersContext.Provider>
                  </NewsContext.Provider>
                </InfoContext.Provider>
              </GalleryContext.Provider>
            </FixturesContext.Provider>
          </ActionContext.Provider>
        </AboutContext.Provider>
      </BrowserRouter>
      {!!isHFShown && !!toastRef.current && <Footer action={handleHfAction} />}
    </>
  );
}

export default App;
