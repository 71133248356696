import React, { useContext, useEffect, useState } from "react";
import WheelComponent from "react-wheel-of-prizes";
import "react-wheel-of-prizes/dist/index.css";
import { PopupContext, UserContext } from "../../../context.js";
import { postDataWithAuth } from "../../../_api/index.js";
import { endPointLive } from "../../endPoints.js";

const SpinWheel = () => {
  const { popupProps } = useContext(PopupContext);
  const { user } = useContext(UserContext);
  const [score, setScore] = useState(0);

  const segments = [
    "Super Raid",
    "Super Tackle",
    "Bonus",
    "Empty Raid ",
    "Penalty",
  ];

  const points = {
    "Super Raid": 3,
    "Super Tackle": 2,
    Bonus: 1,
    "Empty Raid ": 0,
    Penalty: -1,
  };

  const segColors = ["#EE4040", "#F0CF50", "#815CD1", "#3DA5E0", "#34A24F"];

  const saveScore = async (points) => {
    const gamePoints = user.var.points.find(
      ({ game_name }) => game_name === "spin-the-wheel"
    ).points;
    if (parseInt(gamePoints) > points) return;

    const body = new FormData();
    body.append("game_name", "spin-the-wheel");
    body.append("points", points);

    const { score } = await postDataWithAuth({
      body,
      url: endPointLive.saveScore,
      token: user.var.token,
    });

    if (score) {
      const userPoints = user.var.points.map((item) =>
        item.game_name === "spin-the-wheel" ? { ...item, points: score } : item
      );

      user.fn((prev) => ({
        ...prev,
        points: userPoints,
        totalPoints: parseInt(prev.totalPoints) + (points - gamePoints),
      }));
    }
  };

  const onFinished = (winner) => saveScore(points[winner]);

  useEffect(() => {
    document.title = "Patna Pirates | Spin The Wheel";

    popupProps.fn((prev) => ({
      ...prev,
      className: "gallerySeasoModal",
      heading: "Spin The Wheel",
      showHeader: true,
    }));

    window.addEventListener("click", () => setScore(0), false);
    return () => window.removeEventListener("click", () => setScore(0));
  }, []); // eslint-disable-line

  return (
    <div style={{ position: "relative" }}>
      <WheelComponent
        segments={segments}
        segColors={segColors}
        //   winningSegment="won 10"
        onFinished={(winner) => onFinished(winner)}
        primaryColor="black"
        contrastColor="white"
        buttonText="Spin"
        isOnlyOnce={false}
        size={290}
        upDuration={100}
        downDuration={1000}
        fontFamily="Arial"
      />
      {!!score && (
        <div className="sw-score">
          <span>
            You scored {score} point{score > 1 ? "s" : ""}
          </span>
        </div>
      )}
      {/* <div className="sw-popup">You</div> */}
    </div>
  );
};

export default SpinWheel;
